/* eslint-disable no-irregular-whitespace */
const texts = {
  prescreen: `
1. **Introduction**

   1.1. We are committed to safeguarding the privacy of our website visitors, service users, individual customers and customer personnel.

   1.2. This policy applies where we are acting as a data controller with respect to the personal data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.

   1.3. Our website incorporates privacy controls which affect how we will process your personal data. By using the privacy controls, you can specify whether you would like to receive direct marketing communications and limit the collection, sharing and publication of your personal data.

   1.4. We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.

   1.5. In this policy, "we", "us" and "our" refer to _PrescreenAI_. For more information about us, see Section 18, and user (referred to as "You" or "Organization")

2. **The personal data that we collect**

   2.1. In this Section 2 we have set out the general categories of personal data that we process and, in the case of personal data that we did not obtain directly from you, information about the source and specific categories of that data.

   2.2. We may process data enabling us to get in touch with you ("**contact data**"). The contact data may include your name, email address, telephone number, postal address and/or social media account identifiers. The source of the contact data is you or your recruiting agent (either individual or an organization). If you log into our website using a social media account, we will obtain elements of the contact data from the relevant social media account provider.

   2.3. We may process your website user account data ("**account data**"). The account data may include your account identifier, name, email address, business name, account creation and modification dates, website settings and marketing preferences. The primary source of the account data is you and/or your recruiting agent, although some elements of the account data may be generated by our website. If you log into our website using a social media account, we will obtain elements of the account data from the relevant social media account provider.

   2.4. We may process your information included in your personal profile on our website ("**profile data**"). The profile data may include your name, address, telephone number, email address, profile pictures, gender, date of birth, relationship status, interests and hobbies, educational details and employment details. The source of the profile data is you and/or your recruiting agent. If you log into our website using a social media account, we will obtain elements of the profile data from the relevant social media account provider.

   2.5. We may process your personal data that are provided in the course of the use of our services and generated by our services in the course of such use ("**service data**"). The service data may include _These may include name, contact details, resume/CV, employment history, skills, and any other information relevant to the hiring process._ The source of the service data is you and/or your recruiting agent.

   2.6. We may process information contained in or relating to any communication that you send to us or that we send to you ("**communication data**"). The communication data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms.

   2.7. We may process data about your use of our website and services ("**usage data**"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system.

   2.8. We may process _your education skills and employment history data__These may include name, contact details, resume/CV, employment history, skills, and any other information relevant the hiring process_ The source of the service data is you and/or your recruiting agent.

   2.9. Please do not supply any other person's personal data to us, unless we prompt you to do so.

3. **Purposes of processing and legal bases**

   3.1. In this Section 3, we have set out the purposes for which we may process personal data and the legal bases of the processing.

   3.2. **Operations** - We may process your personal data for the purposes of operating our website. The legal basis for this processing is to assess your suitability for a specific position as provided by your recruiting agent, or for general suitability within an industry that is of interest to your recruiting agent. The legal basis for this processing is to assess your fitness with a particular position provided by your recruiting agent, or general fitness within an industry that is of an interest of your recruiting agent.

   3.3. **Publications** - We may process account data, profile data and/or service data for the purposes of publishing such data on our website and elsewhere through our services in accordance with your express instructions. The legal basis for this processing is your consent

   3.4. **Relationships and communications** - We may process contact data, account data, customer relationship data, transaction data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by email, SMS, post, fax and/or telephone, providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website visitors, service users, individual customers and customer personnel, the maintenance of our relationships, enabling the use of our services, and the proper administration of our website, services and business.

   3.5. **Personalization** - We may process account data, service data and/or usage data for the purposes of personalizing the content and advertisements that you see on our website and through our services to ensure that you only see material that is relevant to you. The legal basis for this processing is your consent and/or our legitimate interests, namely offering the best possible experience for our website visitors and service users

   3.6. **Direct marketing** - We may process contact data, account data, profile data, customer relationship data and/or transaction data for the purposes of creating, targeting and sending direct marketing communications by email, SMS, post and/or fax and making contact by telephone for marketing-related purposes. The legal basis for this processing is your consent or our legitimate interests, namely promoting our business and communicating marketing messages and offers to our website visitors and service users

   3.7. **Research and analysis** - We may process usage data, service data and/or transaction data for the purposes of researching and analysing the use of our website and services, as well as researching and analysing other interactions with our business. The legal basis for this processing is your consent and/or our legitimate interests, namely monitoring, supporting, improving and securing our website, services and business generally

   3.8. **Record keeping** - We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally. The legal basis for this processing is our legitimate interests, namely ensuring that we have access to all the information we need to properly and efficiently run our business in accordance with this policy.

   3.9. **Security** - We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity. The legal basis of this processing is our legitimate interests, namely the protection of our website, services and business, and the protection of others.

   3.10. **Insurance and risk management** - We may process your personal data where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks and/or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.

   3.11. **Legal claims** - We may process your personal data where necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.

   3.12. **Legal compliance and vital interests** - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.

4. **Providing your personal data to others**

   4.1. We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.

   4.2. We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice.

   4.3. Your personal data held in our website database will be stored on the servers of our hosting services providers.

   4.4. We may disclose your data to our suppliers or subcontractors insofar as reasonably necessary for further development of our website.

   4.5. We may disclose contact data along with any other personal data contained in enquiries made through our website or services to one or more of those selected third party suppliers of services identified on our website for the purpose of enabling them to contact you so that they can offer, market and sell to you relevant goods and/or services. Each such third party will act as a data controller in relation to the personal data that we supply to it; and upon contacting you, each such third party will supply to you a copy of its own privacy policy, which will govern that third party's use of your personal data.

   4.6. In addition to the specific disclosures of personal data set out in this Section, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.
5. **Retaining and deleting personal data**

   5.1. This Section sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.

   5.2. Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.

   5.3. We will retain your personal data as follows:

- a) contact data will be retained for a minimum period of _2 calendar months_ the date of the most recent contact between you and us, and for a maximum period of _2 calendar years following_ that date;
- b) account data will be retained for a minimum period of _2 calendar months following_ the date of closure of the relevant account, and for a maximum period of _2 calendar years following_ that date;
- c) profile data will be retained for a minimum period of _2 calendar months_ the date of deletion of the profile by you, and for a maximum period of _2 calendar years_ following that date;
- d) customer relationship data will be retained for a minimum period of _2 calendar months following_ the date of termination of the relevant customer relationship and for a maximum period of _2 calendar years following_ that date;
- e) service data will be retained for a minimum period of _2 calendar months following_ the date of termination of the relevant contract, and for a maximum period of _2 calendar years following_ that date;
- f) transaction data will be retained for a minimum period of _2 calendar months following_ the date of the transaction, and for a maximum period of _2 calendar years following_ that date;
- g) communication data will be retained for a minimum period of _2 calendar months following_ the date of the communication in question, and for a maximum period of _2 calendar years following_ that date;
- h) usage data will be retained for a minimum of _2 2 calendar months_ the date of collection; and a maximum period of _2 calendar years following_ that date

   5.4. In some cases it is not possible for us to specify in advance the periods for which your personal data will be retained. In such cases, we will determine the period of retention.

   5.5. We may continue to retain and publish that personal data after the end of the relevant retention period specified in this Section in accordance with the applicable license terms, subject to your data subject rights. If we cease to publish such personal data after the end of the relevant retention period specified in this Section, that personal data will be retained for a minimum period of 2 months and a maximum period of 2 calendar years following the date that publication ceases.

   5.6. Notwithstanding the other provisions of this Section, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.

6. **Security of personal data**

   6.1. We will take appropriate technical and organisational precautions to secure your personal data and to prevent the loss, misuse or alteration of your personal data.

   6.2. We will store your personal data on secure servers.

   6.3. You acknowledge that the transmission of unencrypted (or inadequately encrypted) data over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.

   6.4. You should ensure that your password is not susceptible to being guessed, whether by a person or a computer program. You are responsible for keeping the password you use for accessing our website confidential and we will not ask you for your password (except when you log in to our website).

7. **Your rights**

   7.1. In this Section, we have listed the rights that you have under data protection law.

   7.2. Your principal rights under data protection law are:

- a) **the right to access** - you can ask for copies of your personal data;
- b) **the right to rectification** - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;
- c) **the right to erasure** - you can ask us to erase your personal data;
- d) **the right to restrict processing** - you can ask us to restrict the processing of your personal data;
- e) **the right to object to processing** - you can object to the processing of your personal data;
- f) **the right to data portability** - you can ask that we transfer your personal data to another organizational or to you;
- g) **the right to complain to a supervisory authority** - you can complain about our processing of your personal data; and
- h) **the right to withdraw consent** - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.

   7.3. These rights are subject to certain limitations and exceptions. You can learn more about the rights of data subjects by visiting [https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices\\_en](https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en) and [https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/](https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/).

   7.4. You may exercise any of your rights in relation to your personal data by written notice to us, using the contact details set out below.

8. **Third party websites**

   8.1. Our website includes hyperlinks to, and details of, third party websites.

   8.2. In general we have no control over, and are not responsible for, the privacy policies and practices of third parties.

9. **Personal data of children**

   9.1. Our website and services are targeted at persons over the age of 18 years old

   9.2. If we have reason to believe that we hold personal data of a person under that age in our databases, we will delete that personal data.

10. **Updating information**

   10.1. Please let us know if the personal information that we hold about you needs to be corrected or updated.

11. **Acting as a data processor**

   11.1. The Application does not collect or change the customers data but act as a data processor for data the users add or may add in the future to the Application.

   11.2. The Application generates summary of the data and provide access to that summary by user permissions and role rules.

12. **About cookies**

   12.1. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.

   12.2. Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.

   12.3. Cookies may not contain any information that personally identifies a user, but personal data that we store about you may be linked to the information stored in and obtained from cookies.

13. **Cookies that we use**

   13.1. We use cookies for the following purposes:

- a) **authentication and status** - we use cookies to identify you when you visit our website and as you navigate our website, and to help us determine if you are logged into our website (cookies used for this purpose are: __hjSessionUser_3922347_);
- b) **personalization** - we use cookies to store information about your preferences and to personalize our website for you;
- c) **security** - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally;
- d) **advertising** - we use cookies to help us to display advertisements that will be relevant to you;
- e) **analysis** - we use cookies to help us to analyse the use and performance of our website and services; and
- f) **cookie consent** - we use cookies to store your preferences in relation to the use of cookies more generally.

14. **Cookies used by our service providers**

   14.1. Our service providers use cookies and those cookies may be stored on your computer when you visit our website.

   14.2. We use Google Analytics, which gathers information about the use of our website and uses cookies for this purpose. We use the information gathered by Google Analytics to create reports about the use of our website. You can find out more about Google's use of information by visiting [https://policies.google.com/technologies/partner-sites](https://policies.google.com/technologies/partner-sites) and you can review Google's privacy policy at [https://policies.google.com/privacy](https://policies.google.com/privacy). The cookies used by Google Analytics are named \\_ga and \\_ga+container-id.

15. **Managing cookies**

   15.1. Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser and from version to version. You can obtain up-to-date information about managing cookies via these links:

- a) [https://support.google.com/chrome/answer/95647](https://support.google.com/chrome/answer/95647) (Chrome);
- b) [https://support.mozilla.org/en-US/products/firefox/privacy-and-security](https://support.mozilla.org/en-US/products/firefox/privacy-and-security) (Firefox);
- c) [https://help.opera.com/en/latest/security-and-privacy/](https://help.opera.com/en/latest/security-and-privacy/) (Opera);
- d) [https://support.apple.com/en-gb/guide/safari/welcome/mac](https://support.apple.com/en-gb/guide/safari/welcome/mac) (Safari); and
- e) [https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd](https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd) (Edge).

   15.2. Blocking all cookies will have a negative impact upon the usability of many websites.

   15.3. If you block cookies, you will not be able to use all the features on our website.

16. **Cookie preferences**

   16.1. You can manage your preferences relating to the use of cookies on our website by visiting: [https://prescreenai.com/contact-us](https://prescreenai.com/contact-us)

17. **Amendments**

   17.1. We may update this policy from time to time by publishing a new version on our website.

   17.2. You should check this page occasionally to ensure you are happy with any changes to this policy.

   17.3. We may notify you of significant changes to this policy by email.

18. **Our details**

   18.1. This website is owned and operated by Dynanetix, LLC

   18.2. We are registered State of Delaware, USA and our registered office is at:

         PrescreenAI 

         PO Box 3328

         Fort Lee, NJ 07024

   18.3. You can contact us:

- a) using our website contact form;
- b) by telephone, on the contact number published on our website; or
- c) by email, using the email address published on our website.
`,
};

const policies = {
  localhost: texts.prescreen,
  prescreenai: texts.prescreen,
  'dev.processica.com': texts.prescreen,
};

export default policies;
