import React, { FormEventHandler, useState } from 'react';
import cn from 'classnames';

import useConnection from 'utils/connection';
import { changePassword } from 'store/actions';

import Button from 'components/button';
import Input from 'components/input';

import styles from '../styles.module.scss';

interface IProps {
  className?: string;
  onClose?: () => void;
}

const PasswordForm: React.FC<IProps> = ({ className, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const isOnline = useConnection();

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      Array.from(new FormData(form).entries()).map(([name, value]) => [
        name,
        (value as string).trim(),
      ])
    );
    const newErrors = Object.entries(data)
      .filter(([, value]) => !value.length)
      .map(([name]) => name);
    !newErrors.length &&
      data.newPassword !== data.confirmPassword &&
      newErrors.push('newPassword', 'confirmPassword');
    setErrors(newErrors);
    if (newErrors.length) return;
    try {
      setLoading(true);
      const result = await changePassword(
        data.currentPassword,
        data.newPassword
      );
      result ? onClose?.() : setErrors(['currentPassword']);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className={cn(styles.password, className)}
      onSubmit={handleFormSubmit}
    >
      <Input
        placeholder="Current password"
        name="currentPassword"
        required
        secure
        disabled={loading}
        className={cn({ [styles.error]: errors.includes('currentPassword') })}
      />
      <Input
        placeholder="New password"
        name="newPassword"
        required
        secure
        disabled={loading}
        className={cn({ [styles.error]: errors.includes('newPassword') })}
      />
      <Input
        placeholder="Confirm password"
        name="confirmPassword"
        required
        secure
        disabled={loading}
        className={cn({ [styles.error]: errors.includes('confirmPassword') })}
      />
      <div>
        <Button caption="Cancel" invert disabled={loading} onClick={onClose} />
        <Button caption="Submit" type="submit" loading={loading} disabled={!isOnline} />
      </div>
    </form>
  );
};

export default PasswordForm;
