import { useRef, useState } from 'react';

export const useDebounceProcess = (
  commonDelay: number = 1000
): [boolean, () => void, (delay?: number) => void] => {
  const [working, setWorking] = useState<boolean>(false);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const start = () => {
    setWorking(true);
    clearTimeout(timerRef.current);
  };

  const finish = (delay?: number) => {
    timerRef.current = setTimeout(
      () => setWorking(false),
      delay || commonDelay
    );
  };

  return [working, start, finish];
};
