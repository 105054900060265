const queue: { base64: string; onStart?: () => void }[] = [];
const callbacks: ((error?: unknown) => void)[] = [];
let processing = false;

export const play = (
  base64: string,
  onStart?: () => void,
  onFinish?: () => void
) => {
  queue.push({ base64, onStart });
  if (onFinish && !callbacks.find(callback => callback === onFinish)) {
    callbacks.push(onFinish);
  }
  !processing && processNextAudio();
};

const processNextAudio = async () => {
  if (!queue.length) return;
  processing = true;
  try {
    queue[0].onStart?.();
    await playAudio(`data:audio/mpeg;base64,${queue[0].base64}`);
  } catch (e) {
    console.error(e);
  } finally {
    queue.shift();
    if (queue.length) {
      processNextAudio();
    } else {
      processing = false;
      callbacks.forEach(callback => {
        callback();
        callbacks.pop();
      });
    }
  }
};

let audio: HTMLAudioElement;

export const init = () => {
  if (audio) return;
  audio = new Audio();
  audio.loop = false;
};

const playAudio = (url: string) =>
  new Promise((resolve, reject) => {
    audio.onended = resolve;
    audio.onerror = reject;
    audio.src = url;
    audio.play().catch(reject);
  });

export const mute = (mute: boolean) => audio && (audio.muted = mute);

export const getInited = () => !!audio;
export const getMuted = () => !!audio?.muted;
