import React, {
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';

import useConnection from 'utils/connection';
import { login, register, loadBots, getInvitation } from 'store/actions';

import Button from 'components/button';
import Input from 'components/input';
import Checkbox from 'components/checkbox';

import { getPolicy } from './policy';

import styles from '../styles.module.scss';

interface IProps {
  onOpenPolicy?: () => void;
  className?: string;
}

const Authorization: React.FC<IProps> = ({ onOpenPolicy, className }) => {
  const [policyApplied, setPolicyApplied] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | false>(false);

  const isOnline = useConnection();

  const invitation = getInvitation();
  const needSigningUp = !!invitation;
  const invitationBotName = useMemo(() => {
    try {
      const data = invitation?.split('.')?.[1];
      if (!data) return null;
      const json = atob(data);
      const { bot_name } = JSON.parse(json);
      return bot_name as string;
    } catch (e) {
      return null;
    }
  }, [invitation]);
  const [signingUp, setSigningUp] = useState<boolean>(needSigningUp);

  useEffect(() => setError(false), [signingUp]);

  const handlePolicyClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    onOpenPolicy?.();
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const data = Object.fromEntries(
      Array.from(new FormData(form).entries()).map(([name, value]) => [
        name,
        (value as string).trim(),
      ])
    );
    if (Object.values(data).some(value => !value.length)) return;
    try {
      setError(false);
      setLoading(true);
      const result = await (signingUp ? register : login)(
        data.email,
        data.password
      );
      if (result === 'inactive') {
        setError('User is inactive');
      } else if (!result) {
        setError(signingUp ? 'Something went wrong' : 'Invalid credentials');
      } else {
        const bots = await loadBots();
        if (!bots.length) setError('You are not subscribed to any bot');
      }
    } finally {
      setLoading(false);
    }
  };

  const needPolicy = signingUp && !!getPolicy();

  return (
    <form
      className={cn(styles.auth, { [styles.incorrect]: error }, className)}
      onSubmit={handleFormSubmit}
    >
      <div className={styles.description}>
        {signingUp && (
          <p>
            {invitationBotName
              ? `Welcome to ${invitationBotName} sign up!`
              : 'Welcome to the registration form!'}
            <br />
            Enter your email and desired password to continue.
          </p>
        )}
      </div>
      <p className={styles.error}>{error}</p>
      <Input placeholder="Email" name="email" required disabled={loading} />
      <Input
        placeholder="Password"
        name="password"
        required
        secure
        disabled={loading}
      />
      {needPolicy && (
        <Checkbox checked={policyApplied} onChange={setPolicyApplied}>
          I have read and accept the{' '}
          <a onClick={handlePolicyClick} className={styles.policyLink}>
            Privacy Policy
          </a>
        </Checkbox>
      )}
      <div>
        <Button
          caption={!needSigningUp ? 'Discard' : signingUp ? 'Login' : 'Sign Up'}
          type={needSigningUp ? 'button' : 'reset'}
          invert
          disabled={loading}
          onClick={
            !needSigningUp ? undefined : () => setSigningUp(value => !value)
          }
        />
        <Button
          caption={signingUp ? 'Sign Up' : 'Login'}
          type="submit"
          loading={loading}
          disabled={!isOnline || (signingUp && needPolicy && !policyApplied)}
        />
      </div>
    </form>
  );
};

export default Authorization;
