import React, {
  createElement,
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export interface InputRef {
  setValue: (value: string) => void;
  getValue: (willClear?: boolean) => string;
  getScrollHeight: () => number;
  setFocus: () => void;
}

interface IProps {
  name?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  secure?: boolean;
  className?: string;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  onReturn?: (value: string) => void;
  onEsc?: (value: string) => void;
}

const Input = forwardRef<InputRef, IProps>(
  (
    {
      name,
      placeholder,
      required,
      disabled,
      multiline,
      secure,
      className,
      style,
      onChange,
      onReturn,
      onEsc,
    },
    ref
  ) => {
    const rootRef = useRef<HTMLInputElement>(null);

    const setValue = (value: string) => {
      const input = rootRef.current as HTMLInputElement;
      if (input.value !== value) {
        input.value = value;
        onChange?.(value);
      }
    };

    const getValue = (willClear?: boolean): string => {
      const input = rootRef.current as HTMLInputElement;
      const { value } = input;
      willClear && setValue('');
      return value;
    };

    const getScrollHeight = (): number => {
      const input = rootRef.current as HTMLInputElement;
      input.style.setProperty('height', `0px`);
      const value = input.scrollHeight;
      input.style.removeProperty('height');
      return value;
    };

    const setFocus = () => rootRef.current?.focus();

    useImperativeHandle(ref, () => ({
      setValue,
      getValue,
      getScrollHeight,
      setFocus,
    }));

    return createElement(multiline ? 'textarea' : 'input', {
      ref: rootRef,
      name,
      type: multiline ? undefined : secure ? 'password' : 'text',
      placeholder,
      required,
      disabled,
      style,
      className: cn(styles.root, className),
      onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) =>
        onChange?.(target.value),
      onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key, shiftKey, currentTarget } = event;
        if (key === 'Enter' && !shiftKey && onReturn) {
          onReturn(currentTarget.value);
          event.preventDefault();
        }
        key === 'Escape' && onEsc?.(currentTarget.value);
      },
    });
  }
);

export default Input;
