import {
  IMediaRecorder,
  MediaRecorder,
  register,
} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

connect().then(register);

let audioContext: AudioContext;
let mediaRecorder: IMediaRecorder;
let isPermitted: boolean | undefined;

export const check = async (): Promise<boolean> => {
  if (isPermitted !== undefined) return isPermitted;
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    isPermitted = true;
    return true;
  } catch (err) {
    console.log(err);
    isPermitted = false;
    return false;
  }
};

export const start = async (listener: (buffer: Blob) => void) => {
  stop();
  audioContext = new AudioContext();
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: { echoCancellation: true },
  });
  mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });
  let header: ArrayBuffer;
  mediaRecorder.ondataavailable = async ({ data }) => {
    const arrayBuffer = await data.arrayBuffer();
    if (!header) {
      header = arrayBuffer.slice(0, 44);
      listener(data);
    } else {
      const blob = new Blob([header, arrayBuffer], { type: 'audio/wav' });
      listener(blob);
    }
  };
  mediaRecorder.start(1000);
};

export const stop = () => mediaRecorder?.stop();
