import { useEffect, useState } from 'react';

export type TTheme = 'dark' | 'light';

export const THEME_STORAGE = 'theme';
export const THEME_ATTRIBUTE = 'data-bs-theme';
export const THEME_INITIAL = 'data-theme-initing';

export const getTheme = (): TTheme => {
  const dataTheme = document.documentElement.getAttribute(
    THEME_ATTRIBUTE
  ) as TTheme;
  return dataTheme === 'dark' ? 'dark' : 'light';
};

export const setTheme = (theme: TTheme, store?: boolean) => {
  document.documentElement.setAttribute(THEME_ATTRIBUTE, theme);
  store && localStorage.setItem(THEME_STORAGE, theme);
};

export const toggleTheme = () => {
  const newTheme = getTheme() === 'dark' ? 'light' : 'dark';
  setTheme(newTheme, true);
};

let inited = false;
export const initTheme = () => {
  if (inited) return;

  let isDarkTheme = false;
  const savedTheme = localStorage.getItem(THEME_STORAGE) as TTheme;
  if (savedTheme) {
    isDarkTheme = savedTheme === 'dark';
  } else if (window.matchMedia) {
    isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
  setTheme(isDarkTheme ? 'dark' : 'light');

  window.addEventListener('load', () => {
    document.documentElement.removeAttribute(THEME_INITIAL);
  });

  inited = true;
};

export const useTheme = () => {
  const [theme, _setTheme] = useState<TTheme>(getTheme());

  useEffect(() => {
    const observer = new MutationObserver(list => {
      if (list.find(({ attributeName }) => attributeName === THEME_ATTRIBUTE)) {
        _setTheme(getTheme());
      }
    });
    observer.observe(document.documentElement, { attributes: true });
    return () => observer.disconnect();
  }, []);

  return {
    theme,
    setTheme,
    toggleTheme,
  };
};
