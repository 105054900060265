import React, { CSSProperties } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  size?: number;
  color?: 'invert' | 'dark' | 'light';
  className?: string;
}

const Loading: React.FC<IProps> = ({ size = 32, color, className }) => {
  return (
    <div
      className={cn(styles.root, color && styles[color], className)}
      style={{ '--size': `${size}px` } as CSSProperties}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loading;
