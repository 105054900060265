import React, {
  CSSProperties,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type TSwitchItem<Key> = {
  key: Key;
  caption: string;
};

interface IProps<Key> {
  items: TSwitchItem<Key>[];
  value?: Key;
  onSwitch?: (value: Key) => void;
  className?: string;
}

function Switch<Key>({ items, value, onSwitch, className }: IProps<Key>) {
  const id = useId();
  const rootRef = useRef<HTMLDivElement>(null);

  const [{ left, width }, setOffset] = useState({ left: 0, width: 0 });

  useEffect(() => {
    const updateOffset = () => {
      const root = rootRef.current as HTMLDivElement;
      const index = items.findIndex(({ key }) => key === value);
      if (index < 0) return;
      const button = root.children[index] as HTMLButtonElement;
      setOffset({ left: button.offsetLeft, width: button.offsetWidth });
    };
    updateOffset();
    window.addEventListener('resize', updateOffset);
    return () => window.removeEventListener('resize', updateOffset);
  }, [items, value]);

  return (
    <div ref={rootRef} className={cn(styles.root, className)}>
      {items.map(({ key, caption }) => (
        <button
          key={`switch-${id}-item-${key}`}
          className={cn({ [styles.selected]: key === value })}
          onClick={() => onSwitch?.(key)}
        >
          {caption}
        </button>
      ))}
      <div
        style={{ '--left': left, '--width': width } as CSSProperties}
        className={styles.back}
      />
    </div>
  );
}

export default Switch;
