import { io, Socket } from 'socket.io-client';

import { IMessage, TChatStatus, TUserStatus } from 'types';

import { host } from './index';

// eslint-disable-next-line no-undef
const LOGING = process.env.REACT_APP_LOGING === 'true';

let socket: ReturnType<typeof io>;

let headers: Record<string, string> = {};

export const setToken = (token: string) => void (headers['token'] = token);
export const clearToken = () => void delete headers['token'];

export const setBotId = (botId: string) => void (headers['bot_id'] = botId);
export const clearBotId = () => void delete headers['bot_id'];

type TSocketStatusCallback = (
  isConnected: boolean,
  reason?: Socket.DisconnectReason
) => void;
let socketStatusCallback: TSocketStatusCallback | null = null;
export const handleSocketStatus = (
  listener: TSocketStatusCallback
): (() => void) => {
  socketStatusCallback = listener;
  return () => void (socketStatusCallback = null);
};

export const connectSocket = (voice_id: string, voice_enabled: boolean) => {
  socket = io(host, {
    auth: { ...headers, voice_id, voice_enabled },
    timeout: 30000,
    ackTimeout: 30000,
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 999,
  });
  const handlerConnect = () => {
    LOGING && console.log('socket connected');
    socketStatusCallback?.(true);
  };
  const handlerDisconnect = (reason: Socket.DisconnectReason) => {
    LOGING && console.log('socket disconnected', { reason });
    socketStatusCallback?.(false, reason);
  };
  socket.on('connect', handlerConnect);
  socket.on('disconnect', handlerDisconnect);
  LOGING && console.log('socket trying to connect');
  socket.connect();
  return () => {
    socket.disconnect();
    socket.off('connect', handlerConnect);
    socket.off('disconnect', handlerDisconnect);
    (socket as never as undefined) = undefined;
  };
};

export const emitMessage = (
  message: string,
  voice_id: string,
  voice_enabled: boolean
) => {
  LOGING &&
    console.log('emit', 'message', { message, voice_id, voice_enabled });
  socket?.emit('message', { message, voice_id, voice_enabled });
};

export const emitCubitMessage = (
  message: string,
  voice_id: string,
  voice_enabled: boolean
) => {
  LOGING &&
    console.log('emit', 'cubit_message', { message, voice_id, voice_enabled });
  socket?.emit('cubit_message', { message, voice_id, voice_enabled });
};

export const emitAudio = (audioBlob: Blob) => {
  LOGING &&
    console.log('emit', 'new_audio', {
      type: audioBlob.type,
      size: audioBlob.size,
    });
  socket?.emit('new_audio', { audioBlob });
};

export const emitEditMessage = (message: string, voice_id: string) => {
  LOGING && console.log('emit', 'admin_message', { message, voice_id });
  socket?.emit('admin_message', { message, voice_id });
};

export const handleReadiness = (listener: () => void) => {
  const handler = () => {
    LOGING && console.log('received', 'connected');
    listener();
  };
  socket.on('connected', handler);
  return () => socket?.off('connected', handler);
};

export const handleResponse = (
  listener: (data: Omit<IMessage, 'actor'> & { audio: string }) => void
) => {
  const handler = (data: any) => {
    LOGING && console.log('received', 'response', data);
    listener(data);
  };
  socket.on('response', handler);
  return () => socket?.off('response', handler);
};

export type TDirective = { intent: string; directive: string };
export const handleGodModeResponse = (listener: (data: TDirective) => void) => {
  const handler = (data: {
    added_directive?: TDirective;
    adjusted_directive?: TDirective;
  }) => {
    LOGING && console.log('received', 'god_mode_response', data);
    const { added_directive, adjusted_directive } = data as never;
    const directive = (added_directive || adjusted_directive) as TDirective;
    directive && listener({ ...directive });
  };
  socket.on('god_mode_response', handler);
  return () => socket?.off('god_mode_response', handler);
};

export const handleVoiceToText = (
  listener: (message: string) => void
): (() => void) => {
  const handler = ({ transcript }: { transcript?: string }) =>
    typeof transcript === 'string' && listener(transcript);
  socket.on('voice_to_text', handler);
  return () => socket?.off('voice_to_text', handler);
};

export const handleVoiceRecognizing = (
  listener: (recognizing: boolean) => void
): (() => void) => {
  const handler = ({ is_recognizing }: { is_recognizing?: boolean }) =>
    typeof is_recognizing === 'boolean' && listener(is_recognizing);
  socket.on('voice_to_text', handler);
  return () => socket?.off('voice_to_text', handler);
};

export const handleVoiceActivity = (
  listener: (active: boolean) => void
): (() => void) => {
  const handler = ({ voice_activity }: { voice_activity?: boolean }) => {
    if (typeof voice_activity === 'boolean') {
      LOGING && console.log('received', 'voice_to_text', { voice_activity });
      listener(voice_activity);
    }
  };
  socket.on('voice_to_text', handler);
  return () => socket?.off('voice_to_text', handler);
};

export const handleStartGenerating = (listener: () => void) => {
  const handler = () => {
    LOGING && console.log('received', 'start_response');
    listener();
  };
  socket.on('start_response', handler);
  return () => socket?.off('start_response', handler);
};

export const handleStopGenerating = (listener: (error?: string) => void) => {
  const handler = ({ error }: { error: string }) => {
    LOGING && console.log('received', 'end_response', error);
    listener(error?.length ? error : undefined);
  };
  socket.on('end_response', handler);
  return () => socket?.off('end_response', handler);
};

export const handleChatStatus = (listener: (status: TChatStatus) => void) => {
  const handler = (data: { data: TChatStatus; status: TChatStatus }) => {
    LOGING && console.log('received', 'chat_status', data);
    listener(data.status || data.data);
  };
  socket.on('chat_status', handler);
  return () => socket?.off('chat_status', handler);
};

export const handleUserStatus = (listener: (status: TUserStatus) => void) => {
  const handler = (data: { data: TUserStatus; status: TUserStatus }) => {
    LOGING && console.log('received', 'user_status', data);
    listener(data.status || data.data);
  };
  socket.on('user_status', handler);
  return () => socket?.off('user_status', handler);
};

export const handleWarning = (
  listener: (code: string, message: string) => void
) => {
  const handler = (data: { code: string; message: string }) => {
    LOGING && console.log('received', 'warning', data);
    listener(data.code, data.message);
  };
  socket.on('warning', handler);
  return () => socket?.off('warning', handler);
};

export const handleError = (
  listener: (code: string, message: string) => void
) => {
  const handler = (data: { code: string; message: string }) => {
    LOGING && console.log('received', 'error', data);
    listener(data.code, data.message);
  };
  socket.on('error', handler);
  return () => socket?.off('error', handler);
};
