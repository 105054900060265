import React from 'react';
import cn from 'classnames';

import { useTheme } from 'utils/theme';

import Toggle from 'components/toggle';

import SunIcon from 'assets/icons/sun.svg';
import MoonIcon from 'assets/icons/moon.svg';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
}

const Themer: React.FC<IProps> = ({ className }) => {
  const { theme, toggleTheme } = useTheme();

  return (
    <Toggle
      leftIcon={SunIcon}
      rightIcon={MoonIcon}
      switched={theme === 'dark'}
      onToggle={toggleTheme}
      className={cn(styles.root, className)}
    />
  );
};

export default Themer;
