import { useEffect, useState } from 'react';

const useConnection = () => {
  const [isOnline, setValue] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const setOnline = () => setValue(true);
    const setOffline = () => setValue(false);

    window.addEventListener('online', setOnline, true);
    window.addEventListener('offline', setOffline, true);

    return () => {
      window.removeEventListener('online', setOnline, true);
      window.removeEventListener('offline', setOffline, true);
    };
  }, []);

  return isOnline;
};

export default useConnection;
