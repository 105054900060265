import React, {
  ButtonHTMLAttributes,
  forwardRef,
  useState,
} from 'react';
import cn from 'classnames';

import Loading from 'components/loading';

import styles from './styles.module.scss';

interface IProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'id' | 'onClick'
  > {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  caption?: string;
  icon?: string;
  id?: string | number;
  large?: boolean;
  invert?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  href?: string;
  download?: boolean;
  onClick?: (() => void) | ((id: string) => void) | ((id: number) => void);
}

const Button = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      type = 'button',
      caption,
      icon,
      id,
      large,
      invert,
      disabled,
      loading,
      className,
      href,
      download,
      onClick,
      children,
      ...props
    },
    ref
  ) => {
    const [pending, setPending] = useState<boolean>(false);
    return React.createElement(
      href ? 'a' : 'button',
      {
        ref,
        type,
        disabled: disabled || loading || pending,
        className: cn(
          styles.root,
          {
            [styles.large]: large,
            [styles.invert]: invert,
            [styles.loading]: loading,
          },
          className
        ),
        href,
        download,
        onClick: disabled
          ? (event: MouseEvent) => event.preventDefault()
          : onClick &&
            (async () => {
              try {
                setPending(true);
                await onClick(id as never);
              } finally {
                setPending(false);
              }
            }),
        ...props,
      },
      icon && <img src={icon} />,
      !!caption?.length && <span>{caption}</span>,
      children,
      loading && (
        <div>
          <Loading
            size={large ? 30 : 20}
            color={!invert ? 'light' : undefined}
          />
        </div>
      )
    );
  }
);

export default Button;
