import { observable } from 'mobx';

import { IBot, IChat, IMessage, IUser, TChatStatus } from 'types';
import { IOption } from 'components/select';

interface IApp {
  bots: IBot[];
  cubitLessons: IOption[];
  botId: string;
  voiceId: string;
  cubitLesson: string;
  messages: IMessage[];
  chats: IChat[];
  user: IUser | null;
  status: TChatStatus;
}

export const defState: IApp = {
  bots: [],
  cubitLessons: [],
  botId: '',
  voiceId: '',
  cubitLesson: '',
  messages: [],
  chats: [],
  user: null,
  status: 'inactive',
};

const store = observable<IApp>(defState);

export default store;
