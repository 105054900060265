import React, { useEffect, useId, useRef } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export interface IOption {
  caption: string;
  value: string | number;
}

interface IProps {
  name?: string;
  options: IOption[];
  placeholder?: string;
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  defValue?: IOption['value'];
  className?: string;
  onChange?: (value: string) => unknown | ((value: number) => unknown);
}

const Select: React.FC<IProps> = ({
  name,
  options,
  placeholder,
  multiple,
  required,
  disabled,
  defValue,
  className,
  onChange,
}) => {
  const id = useId();
  const ref = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    let index = options.findIndex(({ value }) => value === defValue);
    index = placeholder ? index + 1 : index;
    ref.current && (ref.current.selectedIndex = index);
  }, [defValue, placeholder, options]);

  return (
    <select
      ref={ref}
      name={name}
      multiple={multiple}
      required={required}
      disabled={disabled}
      className={cn(styles.root, className)}
      onChange={event => onChange?.(event.target.value)}
    >
      {placeholder && (
        <option disabled hidden>
          {placeholder}
        </option>
      )}
      {options.map(({ caption, value }, index) => (
        <option key={`select-${id}-option-${index}`} value={value}>
          {caption}
        </option>
      ))}
    </select>
  );
};

export default Select;
