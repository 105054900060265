import React, { Fragment, UIEventHandler, useEffect, useRef } from 'react';
import cn from 'classnames';

import { IMessage, TImage } from 'types';
import { TDirective } from 'api/socket';
import { frontDate } from 'utils/format';

import Button from 'components/button';
import Loading from 'components/loading';

import InfoIcon from 'assets/icons/info.svg';
import CloseIcon from 'assets/icons/close.svg';

import styles from '../styles.module.scss';

interface IProps {
  loading?: boolean;
  generating?: boolean;
  messages?: IMessage[];
  directive?: TDirective;
  inactive?: boolean;
  typing?: boolean;
  error?: string;
  editing?: string | false;
  className?: string;
  onImageClick?: (url: string) => void;
  onDirectiveClose?: () => void;
}

const Dialog: React.FC<IProps> = ({
  loading,
  generating,
  messages,
  directive,
  inactive,
  typing,
  error,
  editing,
  className,
  onImageClick,
  onDirectiveClose,
}) => {
  const needAutoscrollRef = useRef<boolean>(true);
  const handleScroll: UIEventHandler<HTMLDivElement> = ({ target }) => {
    const div = target as HTMLDivElement;
    needAutoscrollRef.current =
      div.scrollTop >= div.scrollHeight - div.clientHeight - 1;
  };

  // scroll to bottom when receive new message
  const dialogueRef = useRef<HTMLDivElement>(null);
  const scrollDialogueToBottom = () => {
    if (needAutoscrollRef.current && dialogueRef.current) {
      const div = dialogueRef.current as HTMLDivElement;
      setTimeout(() => div.scrollTo(0, div.scrollHeight), 0);
    }
  };
  useEffect(() => {
    scrollDialogueToBottom();
  }, [generating, messages?.length, messages?.at(-1)?.message, inactive]);
  const handleImgLoad = (img: TImage) => {
    messages?.at(-1)?.img === img && scrollDialogueToBottom();
  };

  return (
    <div
      ref={dialogueRef}
      className={cn(styles.messages, className)}
      onScroll={handleScroll}
    >
      {directive && (
        <div className={styles.directive}>
          <div>
            <div>
              <img src={InfoIcon} />
            </div>
            <p>
              <strong>Intent:</strong> {directive.intent}
            </p>
            <p>
              <strong>Directive:</strong> {directive.directive}
            </p>
            <Button icon={CloseIcon} onClick={onDirectiveClose} />
          </div>
        </div>
      )}
      {messages?.map(({ id, message, img, actor, datetime }, index) => {
        const denyTranslate =
          typing && actor === 'assistant' && index === messages?.length - 1;
        return (
          <Fragment key={`messages-${index.toString()}`}>
            {img && (
              <img
                src={img.preview_url}
                alt={message}
                className={styles[actor]}
                onClick={() => onImageClick?.(img?.full_url)}
                onLoad={() => handleImgLoad(img)}
              />
            )}
            {!!message?.trim() && (
              <p
                translate={denyTranslate ? 'no' : undefined}
                className={cn(styles[actor], {
                  [styles.editing]: id === editing,
                  notranslate: denyTranslate,
                })}
              >
                {message}
                <span className={styles.date}>{frontDate(datetime)}</span>
              </p>
            )}
          </Fragment>
        );
      })}
      {generating && (
        <p className={styles.loading}>
          Typing <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      )}
      {!generating && loading && (
        <p className={styles.loading}>
          <Loading size={16} className={styles.spinner} />
          Loading <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      )}
      {!!error?.length && <p className={styles.error}>{error}</p>}
      {!!messages?.length && inactive && (
        <p className={styles.completed}>This chat has been completed</p>
      )}
    </div>
  );
};

export default Dialog;
