import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import { IMessage } from 'types';
import store from 'store';
import { clearChats, loadChats } from 'store/actions';
import { frontDate } from 'utils/format';
import useConnection from 'utils/connection';

import Loading from 'components/loading';
import Select from 'components/select';
import Button from 'components/button';

import Dialog from './dialog';

import styles from '../styles.module.scss';

interface IProps {
  className?: string;
  onClose?: () => void;
}

const Chats: React.FC<IProps> = ({ className, onClose }) => {
  const { chats } = store;

  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>();

  const isOnline = useConnection();

  useEffect(() => {
    setSelected(chats[0]?.id || '');
  }, [chats]);

  useEffect(() => {
    clearChats();
    setLoading(true);
    loadChats().finally(() => setLoading(false));
  }, []);

  const chat = chats.find(({ id }) => id === selected);
  const messages =
    chat?.messages
      ?.map(({ id, content, role, datetime }) =>
        content.map(
          (line): IMessage => ({ id, message: line, actor: role, datetime })
        )
      )
      .flat() || [];

  return (
    <div className={cn(styles.chats, className)}>
      <div>
        <Select
          placeholder="Select session"
          options={chats.map(({ id, last_interaction }) => ({
            value: id,
            caption: frontDate(last_interaction),
          }))}
          disabled={loading || !chats?.length || !isOnline}
          defValue={selected}
          onChange={setSelected}
        />
        <Button onClick={onClose}>Close</Button>
      </div>
      {loading ? (
        <div className={styles.loading}>
          <Loading size={40} />
        </div>
      ) : !chats?.length ? (
        <div className={styles.empty}>{"You don't have any sessions yet"}</div>
      ) : (
        <Dialog messages={messages} />
      )}
    </div>
  );
};

export default observer(Chats);
