/* eslint-disable no-undef */

export type TUrl = string | string[];
export type TQuery = Record<string, string | number | undefined | null>;
export type TMiddlewareError = (response: Response) => void;

const HEADER_TOKEN = 'x-access-token';
const HEADER_BOT = 'x-bot-id';
let defaultHeaders: Record<string, string> = {};

export const setToken = (token: string) =>
  void (defaultHeaders[HEADER_TOKEN] = token);
export const clearToken = () => void delete defaultHeaders[HEADER_TOKEN];

export const setBotId = (botId: string) =>
  void (defaultHeaders[HEADER_BOT] = botId);
export const clearBotId = () => void delete defaultHeaders[HEADER_BOT];

export const host = (
  process.env.REACT_APP_API_HOST || window.location.origin
)?.replace(/\/+$/, '');

export const uri = (endpoint: TUrl, query?: TQuery) => {
  const search = Object.fromEntries(
    Object.entries(query || {}).filter(
      ([, value]) => value !== undefined && value !== null
    )
  );
  return (
    `${host}/${
      typeof endpoint === 'string'
        ? endpoint.replace(/^\/+/, '')
        : endpoint.join('/')
    }` +
    (Object.keys(search).length
      ? '?' + new URLSearchParams(search as never).toString()
      : '')
  );
};

const middlewares: { error?: TMiddlewareError } = {};
export const setErrorMiddleware = (callback: TMiddlewareError) =>
  (middlewares.error = callback);

const checkError = async (response: Response, method: string) => {
  if (response.ok) return;
  middlewares.error?.(response);
  let cause = '';
  try {
    const { error } = await response.json();
    cause = `${error}`;
    // eslint-disable-next-line no-empty
  } catch (e) {
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    throw new Error(
      `${method} ${response.url} ${response.status} ${response.statusText}\n${cause}`,
      { cause }
    );
  }
};

const parseResponse = async (response: Response) => {
  const text = await response.text();
  try {
    const { data } = JSON.parse(text);
    return data;
  } catch (e) {
    return text;
  }
};

export const get = async function <T>(url: TUrl, query?: TQuery): Promise<T> {
  const response = await fetch(uri(url, query), {
    method: 'GET',
    headers: {
      ...defaultHeaders,
    },
  });
  await checkError(response, 'GET');
  return await parseResponse(response);
};

export const post = async function <T>(
  url: TUrl,
  payload?: Record<string, string | number | boolean>
): Promise<T> {
  const response = await fetch(uri(url), {
    method: 'POST',
    headers: {
      ...defaultHeaders,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload || {}),
  });
  await checkError(response, 'POST');
  return await parseResponse(response);
};

export const download = async function (
  url: string,
  query?: TQuery,
  name?: string
): Promise<void> {
  const response = await fetch(uri(url, query), {
    method: 'GET',
    headers: {
      ...defaultHeaders,
    },
  });
  await checkError(response, 'DOWNLOAD');
  const blob = await response.blob();
  const contentType = response.headers.get('content-type') || 'text/plain';
  const ext = contentType.includes('text/plain')
    ? 'txt'
    : contentType.replace(/^.*?\w+\/(\w+).*$/g, '$1');
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([blob]));
  link.setAttribute(
    'download',
    `${(name || url).replace(/^.*?(\w+)$/g, '$1')}.${ext}`
  );
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};
