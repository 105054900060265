import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import { setErrorMiddleware } from 'api';
import { initTheme } from 'utils/theme';
import store from 'store';
import { getBot, logOut } from 'store/actions';

import Chat, { ChatRef } from 'containers/chat';
import Opener from 'containers/opener';

import MusicTemplateDesktop from 'assets/templates/music.jpg';
import MusicTemplateMobile from 'assets/templates/music-mobile.jpg';
import MarketplaceTemplateDesktop from 'assets/templates/marketplace.jpg';
import MarketplaceTemplateMobile from 'assets/templates/marketplace-mobile.jpg';
import GroceryTemplateDesktop from 'assets/templates/grocery.jpg';
import GroceryTemplateMobile from 'assets/templates/grocery-mobile.jpg';
import PsychologistTemplateDesktop from 'assets/templates/psychologist.jpg';
import PsychologistTemplateMobile from 'assets/templates/psychologist-mobile.jpg';
import InsuranceTemplateDesktop from 'assets/templates/insurance.jpg';
import InsuranceTemplateMobile from 'assets/templates/insurance-mobile.jpg';
import SkincareTemplateDesktop from 'assets/templates/skincare.jpg';
import SkincareTemplateMobile from 'assets/templates/skincare-mobile.jpg';
import CubitTemplateDesktop from 'assets/templates/cubit.jpg';
import CubitTemplateMobile from 'assets/templates/cubit-mobile.jpg';

const templates: Array<{
  name: string;
  title: string;
  desktop?: string;
  mobile?: string;
  favicon?: string;
  domains?: string[];
}> = [
  {
    name: 'Music Store Consultant',
    title: 'Music Shop',
    desktop: MusicTemplateDesktop,
    mobile: MusicTemplateMobile,
  },
  {
    name: 'Music Store Consultant 2',
    title: 'Music Shop',
    desktop: MusicTemplateDesktop,
    mobile: MusicTemplateMobile,
  },
  {
    name: 'Sports Store Consultant',
    title: 'Sport shop',
    desktop: MarketplaceTemplateDesktop,
    mobile: MarketplaceTemplateMobile,
  },
  {
    name: 'Psychologist',
    title: 'AI Psychologist',
    desktop: PsychologistTemplateDesktop,
    mobile: PsychologistTemplateMobile,
  },
  {
    name: 'Grocery Store Consultant',
    title: 'Grocery store',
    desktop: GroceryTemplateDesktop,
    mobile: GroceryTemplateMobile,
  },
  {
    name: 'Insurance Consultant',
    title: 'Insurance Consultant',
    desktop: InsuranceTemplateDesktop,
    mobile: InsuranceTemplateMobile,
  },
  {
    name: 'Skincare Store Consultant',
    title: 'Skincare Consultant',
    desktop: SkincareTemplateDesktop,
    mobile: SkincareTemplateMobile,
  },
  {
    name: 'Cubit Assistant',
    title: 'Cubit Assistant',
    desktop: CubitTemplateDesktop,
    mobile: CubitTemplateMobile,
  },
  {
    name: 'Life Coach',
    title: 'Life Coach',
    favicon: 'life-coach.png',
    domains: ['life-coach', 'sifu'],
  },
  {
    name: 'PreScreenAI',
    title: 'PreScreenAI',
    favicon: 'prescreen.png',
    domains: ['prescreenai'],
  },
];

const TITLE = document.title;

import styles from './styles.module.scss';

initTheme();

setErrorMiddleware(({ status }) => {
  if (status !== 401 || !store.user) return;
  logOut();
});

const App: React.FC = () => {
  const { botId } = store;
  const template = templates.find(
    ({ name, domains }) =>
      name === getBot(botId)?.name ||
      domains?.some(chunk => location.host.includes(chunk))
  );

  useEffect(() => {
    const newTitle = template?.title;
    const newFavicon = template?.favicon;
    document.title = !newTitle?.length ? TITLE : newTitle;
    const icon = document.head.querySelector(
      'link[rel="icon"]'
    ) as HTMLLinkElement;
    icon.href = !newFavicon?.length
      ? '/favicon/processica.ico'
      : `/favicon/${newFavicon}`;
  }, [botId]);

  const handleOpenerClick = () => chatRef.current?.toggle();

  const chatRef = useRef<ChatRef>(null);

  useEffect(() => {
    setTimeout(() => chatRef.current?.open(), 500);
  }, []);

  return (
    <>
      <div className={cn(styles.templates)}>
        <img src={template?.desktop} />
        <img src={template?.mobile} />
      </div>
      <Chat ref={chatRef} />
      <Opener onClick={handleOpenerClick} className={styles.opener} />
    </>
  );
};

export default observer(App);
