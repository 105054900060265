import React from 'react';
import cn from 'classnames';

import ChatIcon from 'assets/icons/chat.svg';

import styles from './styles.module.scss';

interface IProps {
  onClick?: () => void;
  className?: string;
}

const Opener: React.FC<IProps> = ({ onClick, className }) => {
  return (
    <button onClick={onClick} className={cn(styles.root, className)}>
      <img src={ChatIcon} alt="Open chat" />
    </button>
  );
};

export default Opener;
